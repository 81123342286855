import { isPortrait, scaleFactor, width } from "..";
import d3 from "../modules/d3";
import { headerDimensions } from "./header";
import { Selection } from "../models/d3";

export let footerContainer = {
  width: isPortrait ? width : null,
  height: isPortrait ? 100 : 120,
  top: (headerDimensions.top + headerDimensions.height + 20) / scaleFactor,
};

let footerDiv: Selection<HTMLElement>;
let firstRow: Selection<HTMLDivElement>;

export function initFooter() {
  footerDiv = d3.select("body").select("#main-div").append("footer");

  firstRow = footerDiv.append("div").attr("id", "first-row");

  firstRow
    .append("h4")
    .style("font-weight", 700)
    .style("line-height", "2em")
    .text("Authors:");

  const authorSpan = footerDiv.append("span").style("line-height", "2em");

  authorSpan.text("Dr. Dirk Steinbach (SPIN Sport Innovation)");
  authorSpan.clone().text("Dina Klingmann (GIZ North Macedonia)");

  setFooterSizes();

  return footerDiv;
}

export function setFooterSizes(lastYScrolled: number = 0) {
  footerContainer = {
    width: isPortrait ? width : null,
    height: isPortrait ? 100 : 120,
    top: (headerDimensions.top + headerDimensions.height + 20) / scaleFactor,
  };

  footerDiv
    .style("padding", isPortrait ? "8px" : `${36 / scaleFactor}px`)
    .style("height", footerContainer.height + "px");

  firstRow
    .select("h4")
    .style("font-size", isPortrait ? "16px" : 24 / scaleFactor + "px");

  footerDiv
    .selectAll("span")
    .style("font-size", isPortrait ? "14px" : 22 / scaleFactor + "px");

  if (isPortrait) {
    footerDiv
      .style("top", footerContainer.top + lastYScrolled! + "px")
      .style("width", footerContainer.width! / scaleFactor - 16 + "px");

    const firstRowImg = firstRow.select("img");

    if (firstRowImg.empty()) {
      firstRow
        .append("img")
        .attr("height", isPortrait ? "45px" : 100 / scaleFactor + "px")
        .attr("src", "/static/images/SPIN_Logo_grau-gruen_RGB.png");
    }

    firstRowImg.attr("src", "/static/images/SPIN_Logo_grau-gruen_RGB.png");
  } else {
    footerDiv.style("bottom", "0px").style("right", "0px").style("top", null);
    firstRow.select("img").remove();
  }
}
