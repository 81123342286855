import { Colors } from "./theme";
import { RoadmapNode } from "./models/roadmap-node";

export const initData = (height: number, width: number): RoadmapNode[] => [
  {
    label: "Intro",
    color: Colors.Fifth,
    name: "intro",
    radius: 30,
    fx: 150,
    fy: height - 150,
    svgDimensions: {
      x: -12,
      y: -18,
      width: 31,
      height: 35,
    },
  },
  {
    label: "What Are Local S4D Networks?",
    color: Colors.Fifth,
    name: "network",
    radius: 40,
    fx: 200,
    fy: height * 0.7,
    svgDimensions: {
      x: -22,
      y: -23,
      width: 38,
      height: 45,
    },
  },
  {
    label: "Process of Network Development",
    color: Colors.Forth,
    name: "process",
    radius: 30,
    fx: 300,
    fy: height * 0.5,
    svgDimensions: {
      x: -17,
      y: -18,
      width: 34,
      height: 35,
    },
  },
  {
    label: "Identify Key Stakeholders",
    color: Colors.Yellow,
    name: "stakeholders",
    radius: 20,
    fx: 500,
    fy: height * 0.4,
    svgDimensions: {
      x: -14,
      y: -12,
      width: 30,
      height: 24,
    },
  },
  {
    label: "Start, Build and Manage a Network",
    color: Colors.Fifth,
    name: "start",
    radius: 30,
    fx: width * 0.35,
    fy: height * 0.5,
    svgDimensions: {
      x: -13,
      y: -17,
      width: 25,
      height: 35,
    },
  },
  {
    label: "Expand the Network",
    color: Colors.Red,
    name: "expand",
    radius: 25,
    fx: width * 0.3,
    fy: height * 0.7,
    svgDimensions: {
      x: -15,
      y: -15,
      width: 30,
      height: 30,
    },
  },
  {
    label: "Set Agenda & Strategize",
    color: Colors.Second,
    name: "strategize",
    radius: 30,
    fx: width * 0.4,
    fy: height * 0.8,
    svgDimensions: {
      x: -18,
      y: -18,
      width: 35,
      height: 35,
    },
  },
  {
    label: "Plan Action",
    color: Colors.Fifth,
    name: "plan-action",
    radius: 40,
    fx: width * 0.45,
    fy: height * 0.65,
    svgDimensions: {
      x: -22,
      y: -23,
      width: 43,
      height: 45,
    },
  },
  {
    label: "Build Capacities",
    color: Colors.First,
    name: "build-capacity",
    radius: 30,
    fx: width / 1.8,
    fy: height * 0.5,
    svgDimensions: {
      x: -19,
      y: -16,
      width: 39,
      height: 35,
    },
  },
  {
    label: "Operate",
    color: Colors.Sixth,
    name: "operate",
    radius: 20,
    fx: width * 0.65,
    fy: height * 0.5,
    svgDimensions: {
      x: -10,
      y: -10,
      width: 20,
      height: 20,
    },
  },
  {
    label: "Evaluate and Adjust",
    color: Colors.Yellow,
    name: "evaluate-adjust",
    radius: 35,
    fx: width * 0.7,
    fy: height * 0.7,
    svgDimensions: {
      x: -20,
      y: -20,
      width: 40,
      height: 40,
    },
  },
  {
    label: "Phasing Out",
    color: Colors.Second,
    name: "phase-out",
    radius: 30,
    fx: width * 0.85,
    fy: height * 0.6,
    svgDimensions: {
      x: -18,
      y: -18,
      width: 36,
      height: 35,
    },
  },
  {
    label: "Experience from the Field",
    color: Colors.Forth,
    name: "experience",
    radius: 30,
    fx: width * 0.8,
    fy: height * 0.4,
    svgDimensions: {
      x: -14,
      y: -18,
      width: 28,
      height: 35,
    },
    subContents: [
      {
        id: "MKD",
        header: "Kriva Palanka - North Macedonia",
      },
      {
        id: "ALB",
        header: "Fier - Albania",
      },
      {
        id: "BIH",
        header: "Brčko - Bosnia and Hercegovina",
      },
      {
        id: "XKX",
        header: "Fushe Kosova / Kosovo Polje - Kosovo",
      },
      {
        id: "cross-regional",
        header: "Cross Regional - All local S4D networks",
      },
    ],
  },
  {
    label: "Conclusions",
    color: Colors.Sixth,
    name: "conclusions",
    radius: 20,
    fx: width * 0.7,
    fy: height * 0.3,
    svgDimensions: {
      x: -7,
      y: -13,
      width: 14,
      height: 25,
    },
  },
  {
    label: "Recommendations",
    color: Colors.Red,
    name: "recommendations",
    radius: 35,
    fx: width * 0.835,
    fy: height * 0.2,
    svgDimensions: {
      x: -20,
      y: -20,
      width: 40,
      height: 40,
    },
  },
  {
    label: "Annex",
    color: Colors.Fifth,
    name: "annex",
    radius: 30,
    fx: width - 100,
    fy: 200,
    svgDimensions: {
      x: -13,
      y: -17,
      width: 29,
      height: 35,
    },
  },
];
