import { select } from "d3-selection";
import { xml, json } from "d3-fetch";
import { interpolateZoom } from "d3-interpolate";
import { transition } from "d3-transition";
import { easeQuadInOut, easeBackOut, easeQuadOut, easeQuadIn } from "d3-ease";
import { zoom, zoomIdentity } from "d3-zoom";

export default {
  select,
  xml,
  interpolateZoom,
  transition,
  easeQuadInOut,
  easeBackOut,
  easeQuadOut,
  easeQuadIn,
  json,
  zoom,
  zoomIdentity,
};
