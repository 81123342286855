export const colorIDs = [
  "First",
  "Second",
  "Third",
  "Forth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Red",
  "Yellow",
] as const;

export type ColorId = typeof colorIDs[number];

export type Color =
  | "#007BFF"
  | "#009FA8"
  | "#00345F"
  | "#00576F"
  | "#187999"
  | "#57C6EB"
  | "#80CFD3"
  | "#DF3A3A"
  | "#FFE600";

export const Colors: Record<ColorId, Color> = {
  First: "#007BFF",
  Second: "#009FA8",
  Third: "#00345F",
  Forth: "#00576F",
  Fifth: "#187999",
  Sixth: "#57C6EB",
  Seventh: "#80CFD3",
  Red: "#DF3A3A",
  Yellow: "#FFE600",
};
