import { Selection } from "d3";

export const wrapText = (
  text: Selection<SVGTextElement, unknown, HTMLElement, any>,
  width: number
) => {
  const words = text.text().split(/\s+/).reverse();
  const lineHeight = 1.1; // ems
  const y = text.attr("y");
  const x = text.attr("x");

  let line: string[] = [];
  let word;
  let lineNumber = 0;
  let tspan = text.text(null).append("tspan").attr("y", y).attr("x", x);

  while ((word = words.pop())) {
    line.push(word);
    tspan.text(line.join(" "));

    if (tspan.node()!.getComputedTextLength() > width) {
      line.pop();
      tspan.text(line.join(" "));
      line = [word];
      tspan = text
        .append("tspan")
        .attr("y", y)
        .attr("x", x)
        .attr("dy", ++lineNumber * lineHeight + "em")
        .text(word);
    }
  }
};
