import { isPortrait, scaleFactor, width } from "..";
import d3 from "../modules/d3";
import { Selection } from "../models/d3";

export let headerDimensions = setHeaderDimensions();

let headerDiv: Selection<HTMLDivElement>;
let firstRow: Selection<HTMLDivElement>;
let gizLogo: Selection<HTMLImageElement>;
let s4dLogo: Selection<HTMLImageElement>;
let mainTitle: Selection<HTMLHeadingElement>;
let subTitle: Selection<HTMLHeadingElement>;

export function initHeader() {
  const titleContent = `How to use Local <span class="booster">Sport for Development</span> Networks to initiate effective social change`;
  const subtitleContent =
    "A practical roadmap based on the experience from a pilot program in the Western Balkans";

  headerDiv = d3
    .select("body")
    .select("#main-div")
    .append("div")
    .attr("id", "title-section")
    .style("position", "fixed")
    .style("pointer-events", "none");

  firstRow = headerDiv.append("div");

  gizLogo = firstRow
    .append("img")
    .attr("src", "/static/images/german-cooperation-giz-logo.png");

  s4dLogo = firstRow.append("img").attr("src", "/static/images/S4D-logo.png");

  mainTitle = headerDiv
    .append("h1")
    .attr("class", "mdc-typography--headline1")
    .html(titleContent);

  subTitle = headerDiv
    .append("h2")
    .attr("class", "mdc-typography--headline5")
    .text(subtitleContent);

  setHeaderSizes();

  return headerDiv;
}

export function setHeaderSizes(lastYScrolled: number = 0) {
  headerDimensions = setHeaderDimensions();

  headerDiv
    .style("width", headerDimensions.width / scaleFactor + "px")
    .style("height", headerDimensions.height / scaleFactor + "px")
    .style("left", headerDimensions.left / scaleFactor + "px")
    .style("top", headerDimensions.top / scaleFactor + lastYScrolled! + "px");

  gizLogo.attr("height", isPortrait ? "65px" : 150 / scaleFactor + "px");
  s4dLogo.attr("height", isPortrait ? "45px" : 100 / scaleFactor + "px");

  let spinLogo: Selection<HTMLImageElement> = firstRow.select("#spin-logo");

  if (isPortrait) {
    spinLogo.remove();
  } else {
    if (spinLogo.empty()) {
      spinLogo = firstRow
        .append("img")
        .attr("id", "spin-logo")
        .attr("src", "/static/images/SPIN_Logo_grau-gruen_RGB.png");
    }

    spinLogo.attr("height", isPortrait ? "45px" : 100 / scaleFactor + "px");
  }

  mainTitle
    .style("font-size", isPortrait ? "22px" : 48 / scaleFactor + "px")
    .style("margin-bottom", 10 / scaleFactor + "px");

  subTitle.style("font-size", isPortrait ? "16px" : 24 / scaleFactor + "px");
}

function setHeaderDimensions() {
  return {
    width: isPortrait ? width : 1180,
    height: isPortrait ? 250 : 350,
    left: isPortrait ? 0 : 20,
    top: isPortrait ? 10 : 20,
  };
}
