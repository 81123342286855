import { initData } from "./data";
import { wrapText } from "./utils/svg";
import { RoadmapNode } from "./models/roadmap-node";
import d3 from "./modules/d3";
import {
  BaseType,
  SimulationLinkDatum,
  ZoomView,
  Transition,
  ZoomBehavior,
  ZoomInterpolator,
} from "d3";
import "./app.scss";
import { Colors } from "./theme";
import {
  headerDimensions,
  initHeader,
  setHeaderSizes,
} from "./components/header";
import {
  footerContainer,
  initFooter,
  setFooterSizes,
} from "./components/footer";
import { INavigationActions } from "./models/navigation-actions";

export let isSmallDevice = window.innerWidth < 768;
export let isPortrait = window.innerHeight > window.innerWidth;

let relativeWidth = isPortrait ? window.innerWidth * 1.2 : 1920;
export let scaleFactor = relativeWidth / window.innerWidth;

const hoverScaleDelta = 10;

export let height = window.innerHeight * scaleFactor;
export let width = relativeWidth;
export let screenRatio = width / height;

let data = initData(height, width);
data.forEach((node, index) => (node.index = index));

let selectedNode: RoadmapNode | null = null;
let hoveredNode: RoadmapNode | null = null;

let initialTransform: ZoomView = [width / 2, height / 2, width];
let initialPanOffsetY = height / 2;
let currentTransform: ZoomView = [...initialTransform];
let yScrolled: number = 0;
let lastYScrolled: number = 0;
let zoomPan: ZoomBehavior<SVGSVGElement, unknown>;
let keydownListener: (this: Document, ev: KeyboardEvent) => any;
let resizeTimeout: NodeJS.Timeout;
const resizeDelay = 10;
let shouldUpdateDimensions = true;
let detailsModule: typeof import("./components/details/details");
let experienceModule: typeof import("./components/experience");

const visitedRoadmapNodesIndexes = new Array<boolean>(data.length);
const iconMemo = new Map<string, HTMLElement>();

const body = d3.select("body");

export const svgContainer = body
  .select("#main-div")
  .append("svg")
  .attr("viewBox", [0, 0, width, height]);

const g = svgContainer.append("g");

if (isPortrait) {
  setPortraitNodePositions();
  zoomPan = getZoomPan();
  svgContainer.call(zoomPan);
}

const titleSection = initHeader();
const footerSection = initFooter();
const dropshadowFilter = defineDropShadowDefs();
const labelGroup = g.append("g").attr("id", "label-group");
const ctaDropshadow = dropshadowFilter.clone(true).attr("id", "dropshadow-cta");
let links = buildLinks(data);
let linkSelection = drawLinks(links);
let nodeSelection = drawNodes(data);

if (isPortrait) {
  data.forEach((node) => {
    const label = getOrCreateNodeLabel(node);
    label.attr("opacity", 1).attr("font-size", 24);
  });
} else {
  initAccessability();
}

hightlightCTANodeAt(0);
initResize();

window.addEventListener("load", function () {
  setTimeout(function () {
    // This hides the address bar:
    window.scrollTo(0, 1);
  }, 0);
});

function setPortraitNodePositions() {
  const mobileOffsetX = 70;
  const firstNodeY = 500;
  const deltaY = 150;

  data[0].index = 0;
  data[0].fx = mobileOffsetX;
  data[0].fy = firstNodeY;
  data[0].x = data[0].fx!;
  data[0].y = data[0].fy!;

  for (let i = 1; i < data.length; i++) {
    data[i].fy = i * deltaY + firstNodeY;

    if (i % 2 == 0) {
      data[i].fx = mobileOffsetX;
    } else {
      data[i].fx = width - mobileOffsetX;
    }

    data[i].y = data[i].fy!;
    data[i].x = data[i].fx!;
    data[i].index = i;
  }
}

function initResize() {
  d3.select(window).on("resize.index", function () {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(resize, resizeDelay);
  });
}

function disposeResize() {
  d3.select(window).on("resize.index", null);
}

function resize() {
  isSmallDevice = window.innerWidth < 768;
  isPortrait = window.innerHeight > window.innerWidth;
  relativeWidth = isPortrait ? window.innerWidth * 1.2 : 1920;
  scaleFactor = relativeWidth / window.innerWidth;
  height = window.innerHeight * scaleFactor;
  width = relativeWidth;
  screenRatio = width / height;

  if (!shouldUpdateDimensions) return;

  initialPanOffsetY = height / 2;
  initialTransform = [
    width / 2,
    isPortrait ? currentTransform[1] : height / 2,
    width,
  ];
  currentTransform = [...initialTransform];

  if (hoveredNode) unhoverNode();

  svgContainer.attr("viewBox", [0, 0, width, height]);

  labelGroup.selectAll("*").remove();

  data = initData(height, width);
  data.forEach((node, index) => (node.index = index));

  if (isPortrait) {
    setPortraitNodePositions();
    zoomPan = getZoomPan();
    svgContainer.call(zoomPan);
    svgContainer.call(zoomPan.transform, d3.zoomIdentity);
    setHeaderSizes(lastYScrolled);
    setFooterSizes(lastYScrolled);
  } else {
    svgContainer.call(d3.zoom<SVGSVGElement, unknown>().on("zoom", null));
    currentTransform = [...initialTransform];
    g.attr("transform", null);
    setHeaderSizes();
    setFooterSizes();
  }

  links = buildLinks(data);
  linkSelection = drawLinks(links);
  nodeSelection = drawNodes(data);

  if (isPortrait) {
    data.forEach((node) => {
      const label = getOrCreateNodeLabel(node);
      label.attr("opacity", 1).attr("font-size", 24);
    });
  }

  animateGraphOpacity(d3.transition(), 1);
}

function initAccessability() {
  if (!keydownListener) {
    keydownListener = initKeydownEvents();
  }

  document.addEventListener("keydown", keydownListener);
}

function initKeydownEvents(): (this: Document, ev: KeyboardEvent) => any {
  return (e) => {
    e = e || window.event;

    if (e.repeat) return;

    switch (e.key) {
      case "ArrowLeft":
        if (!hoveredNode) {
          initHoveredNode();
        } else {
          const isFirstNode = hoveredNode.index === data[0].index;
          if (!isFirstNode) {
            const previousNode = data[hoveredNode.index! - 1];
            unhoverNode(() => hoverNode(previousNode));
          }
        }
        break;

      case "ArrowRight":
        if (!hoveredNode) {
          initHoveredNode();
        } else {
          const isLastNode = hoveredNode.index === data[data.length - 1].index;
          if (!isLastNode) {
            const nextNode = data[hoveredNode.index! + 1];
            unhoverNode(() => hoverNode(nextNode));
          }
        }
        break;

      case "Enter":
        if (!hoveredNode) {
          initHoveredNode();
        } else {
          const node = hoveredNode;
          unhoverNode(() => zoomNode(node));
        }
        break;

      case "Escape":
        if (hoveredNode) unhoverNode();
        break;
    }

    function initHoveredNode() {
      const nextNode = data.filter(
        (x) => !visitedRoadmapNodesIndexes[x.index!]
      )[0];
      hoverNode(nextNode ?? data[0]);
    }
  };
}

function drawNodes(data: RoadmapNode[]) {
  g.selectAll("g.node").remove();

  const node = g
    .selectAll("g.node")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "node")
    .on("mouseover", onMouseover)
    .on("mouseout", onMouseout)
    .on("click", onClicked);

  node
    .append("circle")
    .attr("r", (d) => d.radius)
    .attr("fill", (d) => d.color);

  node
    .attr("cx", (d) => d.fx!)
    .attr("cy", (d) => d.fy!)
    .attr("transform", (d) => `translate(${d.fx}, ${d.fy})`);

  node.each(function (d) {
    if (!d.name) return;

    const nodeGroup = d3.select(this);
    const iconUrl = `/static/icons/${d.name}.svg`;

    const handleIconXml = (element: HTMLElement) => {
      const importedNode = document.importNode(element, true);
      nodeGroup.node()!.append(importedNode);
      d3.select(importedNode)
        .attr("pointer-events", "none")
        .attr("height", d!.svgDimensions!.height)
        .attr("width", d!.svgDimensions!.width)
        .attr("x", d!.svgDimensions!.x)
        .attr("y", d!.svgDimensions!.y);
    };

    if (iconMemo.has(iconUrl)) {
      handleIconXml(iconMemo.get(iconUrl)!);
    } else {
      d3.xml(iconUrl).then((xml) => {
        handleIconXml(xml.documentElement);
        iconMemo.set(iconUrl, xml.documentElement);
      });
    }
  });

  return node;
}

function drawLinks(links: SimulationLinkDatum<RoadmapNode>[]) {
  g.selectAll("path").remove();
  g.selectAll("line").remove();

  if (isPortrait) {
    const drawD = (d: SimulationLinkDatum<RoadmapNode>) => {
      const source = d.source! as RoadmapNode;
      const target = d.target! as RoadmapNode;

      if (!source.fx || !source.fy || !target.fx || !target.fy) {
        throw "Nodes don't have fixed position defined!";
      }

      const isFacingRight = source.fx > target.fx;

      return `
      M 
        ${source.fx},${source.fy} 
      C 
        ${(source.fx + target.fx) * (isFacingRight ? 0.75 : 0.25)} ${target.fy} 
        ${(source.fx + target.fx) * (isFacingRight ? 0.25 : 0.75)} ${source.fy} 
        ${target.fx} ${target.fy}
    `;
    };

    return g
      .selectAll("path")
      .data(links)
      .join("path")
      .attr("class", "connection-line")
      .attr("d", drawD);
  } else {
    return g
      .selectAll("line")
      .data(links)
      .enter()
      .append("line")
      .attr("class", "connection-line")
      .attr("x1", (d) => (d.source as RoadmapNode).fx!)
      .attr("y1", (d) => (d.source as RoadmapNode).fy!)
      .attr("x2", (d) => (d.target as RoadmapNode).fx!)
      .attr("y2", (d) => (d.target as RoadmapNode).fy!);
  }
}

function buildLinks(data: RoadmapNode[]): SimulationLinkDatum<RoadmapNode>[] {
  const links: SimulationLinkDatum<RoadmapNode>[] = [];

  for (let index = 1; index < data.length; index++) {
    const link: SimulationLinkDatum<RoadmapNode> = {
      source: data[index - 1],
      target: data[index],
    };

    links.push(link);
  }

  return links;
}

function onClicked(this: SVGGElement, event: any, d: RoadmapNode) {
  event.stopPropagation();

  if (hoveredNode) {
    d3.select(this)
      .select("svg")
      .transition()
      .attr("height", hoveredNode.svgDimensions!.height)
      .attr("width", hoveredNode.svgDimensions!.width)
      .attr("x", hoveredNode.svgDimensions!.x)
      .attr("y", hoveredNode.svgDimensions!.y);

    hoveredNode = null;
  }

  zoomNode(d);
}

function zoomNode(node: RoadmapNode) {
  document.removeEventListener("keydown", keydownListener);
  shouldUpdateDimensions = false;

  if (isPortrait) {
    initialTransform[1] = currentTransform[1];
    lastYScrolled = yScrolled;
  }
  svgContainer.on(".zoom", null);
  selectedNode = node;
  visitedRoadmapNodesIndexes[node.index!] = true;

  nodeSelection
    .select("circle")
    .transition()
    .attr("r", (d) => d.radius);
  nodeSelection.transition().attr("filter", null).style("cursor", "default");
  nodeSelection.on("mouseover", null).on("mouseout", null).on("click", null);

  const targetZoomView = getNodeZoomView(node);
  let i: ZoomInterpolator;
  let transition: Transition<BaseType, unknown, null, undefined>;

  i = d3.interpolateZoom(currentTransform, targetZoomView);
  transition = d3.transition().ease(d3.easeQuadInOut).duration(i.duration);

  titleSection
    .transition(transition)
    .style("opacity", 0)
    .style(
      "top",
      -(headerDimensions.height + footerContainer.height) + lastYScrolled + "px"
    );

  if (isPortrait) {
    footerSection
      .transition(transition)
      .style("opacity", 0)
      .style("top", -footerContainer.height + lastYScrolled + "px");
  } else {
    footerSection
      .transition(transition)
      .style("opacity", 0)
      .style("bottom", -footerContainer.height + "px");
  }

  g.transition(transition).attrTween(
    "transform",
    () => (t) => transform((currentTransform = i(t)))
  );

  animateGraphOpacity(transition, 0, node.index);

  transition.on("end", async () => await onZoomNodeFinished());

  return transition;
}

function zoomFromNodeToNode(nextNode: RoadmapNode) {
  let i: ZoomInterpolator;
  const targetZoomView = getNodeZoomView(nextNode);

  if (isPortrait) {
    initialTransform[1] = targetZoomView[1];
    lastYScrolled = yScrolled;
    i = (d3.interpolateZoom as any).rho(1.5)(currentTransform, targetZoomView);
  } else {
    i = (d3.interpolateZoom as any).rho(2)(currentTransform, targetZoomView);
  }

  const transition = d3
    .transition()
    .ease(d3.easeQuadInOut)
    .duration(i.duration);

  g.transition(transition).attrTween(
    "transform",
    () => (t) => transform((currentTransform = i(t)))
  );

  const halfDuration = i.duration / 2;
  const zoomOutTransition = d3.transition().duration(halfDuration);
  animateGraphOpacity(zoomOutTransition, 1);

  zoomOutTransition.on("end", () => {
    selectedNode = nextNode;
    visitedRoadmapNodesIndexes[nextNode.index!] = true;
    const zoomInTransition = d3.transition().duration(halfDuration);
    animateGraphOpacity(zoomInTransition, 0, nextNode.index);
    zoomInTransition.on("end", async () => await onZoomNodeFinished());
  });
}

function getNodeZoomView(node: RoadmapNode): ZoomView {
  const selectedElement = nodeSelection.filter((x) => x.index === node.index);
  const positionValue = selectedElement.attr("transform").split(/[\s,()]+/);
  const x = parseFloat(positionValue[1]);
  const y = parseFloat(positionValue[2]);

  if (isPortrait) {
    return [
      x + 3.5 * node.radius,
      y + (5 * node.radius) / screenRatio - 1.5 * node.radius,
      10 * node.radius,
    ];
  } else {
    return [
      x + 4.7 * node.radius,
      y + (4 * node.radius) / screenRatio,
      16 * node.radius,
    ];
  }
}

function animateGraphOpacity(
  transition: Transition<BaseType, unknown, null, undefined>,
  targetOpacity: number,
  excludedNodeIndex?: number
) {
  let nodes = nodeSelection;

  if (excludedNodeIndex !== undefined) {
    nodes = nodeSelection.filter((x) => x.index !== excludedNodeIndex);
  }

  // Fade visited & highlight next in line
  if (targetOpacity === 1) {
    const visitedNodesSelection = nodeSelection.filter(
      (x) => visitedRoadmapNodesIndexes[x.index!]
    );
    nodes = nodes.filter((x) => !visitedRoadmapNodesIndexes[x.index!]);

    if (nodes.empty()) {
      nodes = visitedNodesSelection;
    } else {
      visitedNodesSelection
        .transition(transition)
        .style("stroke-opacity", 0.8)
        .style("fill-opacity", 0.8);

      const nextNode = data.filter(
        (x) => !visitedRoadmapNodesIndexes[x.index!]
      )[0];

      if (nextNode) {
        d3.select("#dropshadow")
          .select("feFlood")
          .attr("flood-color", nextNode.color);

        hightlightCTANodeAt(nextNode.index!, transition);
      }
    }

    if (isPortrait) {
      labelGroup
        .selectAll("text")
        .transition(transition)
        .attr("opacity", targetOpacity);
    }
  } else if (targetOpacity === 0) {
    labelGroup
      .selectAll("text")
      .transition(transition)
      .attr("opacity", targetOpacity);

    nodeSelection
      .transition(transition)
      .attr("filter", null)
      .style("cursor", "default");
  }

  nodes
    .transition(transition)
    .style("stroke-opacity", targetOpacity)
    .style("fill-opacity", targetOpacity);

  linkSelection.transition(transition).style("stroke-opacity", targetOpacity);
}

function hightlightCTANodeAt(
  index: number,
  transition?: Transition<BaseType, unknown, null, undefined>
) {
  if (!transition) {
    transition = d3.transition();
  }

  ctaDropshadow.select("feFlood").attr("flood-color", data[index].color);

  nodeSelection
    .filter((x) => x.index === index)
    .transition(transition)
    .attr("filter", "url(#dropshadow-cta)")
    .style("stroke-opacity", 1)
    .style("fill-opacity", 1);
}

function transform([x, y, r]: number[]) {
  return `
    translate(${width / 2}, ${height / 2})
    scale(${width / r})
    translate(${-x}, ${-y})
  `;
}

function onMouseover(event: any, d: RoadmapNode) {
  event.stopPropagation();
  hoverNode(d);
}

function hoverNode(d: RoadmapNode) {
  if (hoveredNode) {
    if (hoveredNode === d) return;

    unhoverNode(() => handleHover());
  } else {
    handleHover();
  }

  function handleHover() {
    hoveredNode = d;

    const selectedElement = nodeSelection.filter(
      (node) => node.index === d.index
    );
    const transition = d3.transition().duration(150);

    d3.select("#dropshadow").select("feFlood").attr("flood-color", d.color);

    let labelDropshadow = d3.select("#dropshadow-label");

    if (labelDropshadow.empty()) {
      labelDropshadow = d3
        .select("#dropshadow")
        .clone(true)
        .attr("id", "dropshadow-label");
    }

    labelDropshadow
      .select("feFlood")
      .transition(transition)
      .attr("flood-color", "white");

    selectedElement
      .select("circle")
      .transition(transition)
      .attr("r", d.radius + hoverScaleDelta);

    selectedElement
      .select("svg")
      .transition(transition)
      .attr("height", hoveredNode.svgDimensions!.height + hoverScaleDelta)
      .attr(
        "width",
        hoveredNode.svgDimensions!.width +
          (hoveredNode.svgDimensions!.width /
            hoveredNode.svgDimensions!.height) *
            hoverScaleDelta
      )
      .attr("x", hoveredNode.svgDimensions!.x - hoverScaleDelta / 2)
      .attr("y", hoveredNode.svgDimensions!.y - hoverScaleDelta / 2);

    selectedElement
      .transition(transition)
      .style("cursor", "pointer")
      .attr("filter", "url(#dropshadow)")
      .style("stroke-opacity", 1)
      .style("fill-opacity", 1);

    const label = getOrCreateNodeLabel(hoveredNode);

    label
      .transition(transition)
      .attr("opacity", 1)
      .attr("filter", "url(#dropshadow-label)");

    animateGraphOpacity(transition, 0.3, d.index);
  }
}

function unhoverNode(onEnd?: () => void) {
  if (hoveredNode === null) {
    console.error("No hovered node!");
    return;
  }

  const selectedElement = nodeSelection.filter(
    (node) => node.index === hoveredNode!.index
  );
  const transition = d3.transition().duration(100);

  selectedElement
    .select("circle")
    .transition(transition)
    .attr("r", hoveredNode!.radius);

  const label = getOrCreateNodeLabel(hoveredNode!);

  label.transition(transition).attr("opacity", 0).attr("filter", null);

  selectedElement
    .select("svg")
    .transition(transition)
    .attr("height", hoveredNode!.svgDimensions!.height)
    .attr("width", hoveredNode!.svgDimensions!.width)
    .attr("x", hoveredNode!.svgDimensions!.x)
    .attr("y", hoveredNode!.svgDimensions!.y);

  hoveredNode = null;

  selectedElement
    .transition(transition)
    .attr("filter", null)
    .style("cursor", "default");

  animateGraphOpacity(transition, 1);

  if (onEnd) {
    transition.on("end", onEnd);
  }
}

function getOrCreateNodeLabel(node: RoadmapNode) {
  let label = labelGroup.select<SVGTextElement>(`#node-label-${node.index!}`);

  if (!label.empty()) {
    return setNodeLabelPosition();
  }

  label = labelGroup
    .append("text")
    .attr("id", `node-label-${node.index!}`)
    .attr("class", "node-label")
    .attr("font-family", "Raleway")
    .attr("font-weight", isPortrait ? 600 : 800)
    .attr("font-size", 36)
    .attr("opacity", 0)
    .style("fill", isPortrait ? Colors.Fifth : node.color)
    .attr("pointer-events", "none")
    .text(node.label)
    .attr("x", node.fx! + 50)
    .attr("y", node.fy! + 10)
    .style("text-anchor", "start");

  if (isPortrait) {
    wrapText(label, width);
  }

  return setNodeLabelPosition();

  function setNodeLabelPosition() {
    let isOverflow: boolean;

    if (isPortrait) {
      isOverflow = node.index! % 2 != 0;
      label.style("dominant-baseline", "ideographic");
    } else {
      const dimensions = label.node()?.getBoundingClientRect();
      isOverflow =
        node.isOverflow || dimensions!.right > width / scaleFactor - 100;
    }

    const dx = node.radius + (isPortrait ? 0 : hoverScaleDelta) + 20;
    const dy = 10;

    if (isOverflow) {
      label = label
        .attr("x", node.fx! - dx)
        .attr("y", node.fy! + dy)
        .style("text-anchor", "end");

      if (isPortrait) {
        label.selectAll("tspan").attr("x", node.fx! - dx);
      }

      node.isOverflow = true;
    } else {
      label = label
        .attr("x", node.fx! + dx)
        .attr("y", node.fy! + dy)
        .style("text-anchor", "start");
    }

    return label;
  }
}

function onMouseout(this: SVGGElement, event: any, d: RoadmapNode) {
  event.stopPropagation();
  unhoverNode();
}

function resetZoom(onEnd?: () => void) {
  resize();
  lastYScrolled = -initialTransform[1] + initialPanOffsetY;

  const i = d3.interpolateZoom(currentTransform, initialTransform);
  const transition = d3
    .transition()
    .ease(d3.easeQuadInOut)
    .duration(i.duration);

  titleSection
    .transition(transition)
    .style("opacity", 1)
    .style("top", headerDimensions.top + lastYScrolled + "px");

  if (isPortrait) {
    labelGroup.selectAll("text").transition(transition).attr("opacity", 1);

    footerSection
      .transition(transition)
      .style("opacity", 1)
      .style("top", footerContainer.top + lastYScrolled + "px");
  } else {
    footerSection
      .transition(transition)
      .style("opacity", 1)
      .style("bottom", "0px");
  }

  const transformTransition = g
    .transition(transition)
    .attrTween("transform", () => (t) => transform((currentTransform = i(t))));

  animateGraphOpacity(transition, 1);

  if (onEnd) {
    transformTransition.on("end", onEnd);
  }
}

async function onZoomNodeFinished() {
  if (!selectedNode) {
    console.error("No selected node after zoom!");
    return;
  }

  const navigationActions: INavigationActions = {
    onNext,
    onPrevious,
    onReset,
  };

  if (selectedNode.name === "experience") {
    const module = await getExperienceModule();
    await module.initExperienceComponent(
      selectedNode!,
      navigationActions,
      data
    );
  } else {
    const module = await getDetailsModule();
    await module.initDetailsComponent(selectedNode!, navigationActions, data);
  }
}

function onNext() {
  getDetailsModule().then((module) => {
    module.exitDetailsComponent((hasResized: boolean) => {
      if (selectedNode == null) {
        console.error("No selected node");
        return;
      }
      let nodeIndex = selectedNode.index!;
      nodeIndex++;
      if (nodeIndex == data.length) {
        console.error("Last node, no next node!");
        return;
      }
      const nextNode = data[nodeIndex];
      if (hasResized) {
        resetZoom(() => {
          shouldUpdateDimensions = true;
          resize();
          shouldUpdateDimensions = false;
          zoomNode(nextNode);
        });
      } else {
        zoomFromNodeToNode(nextNode);
      }
    });
  });
}

function onPrevious() {
  getDetailsModule().then((module) => {
    module.exitDetailsComponent((hasResized: boolean) => {
      if (selectedNode == null) {
        console.error("No selected node");
        return;
      }
      if (selectedNode.index === 0) {
        console.error("First node, no previous node!");
        return;
      }
      const previousNode = data[selectedNode.index! - 1];
      if (hasResized) {
        resetZoom(() => {
          shouldUpdateDimensions = true;
          resize();
          shouldUpdateDimensions = false;
          zoomNode(previousNode);
        });
      } else {
        zoomFromNodeToNode(previousNode);
      }
    });
  });
}

function onReset() {
  getDetailsModule().then((module) => {
    module.exitDetailsComponent(() => {
      selectedNode = null;
      resetZoom(() => {
        shouldUpdateDimensions = true;
        resize();
        initAccessability();
      });
    });
  });
}

function defineDropShadowDefs() {
  const defs = g.append("defs");

  const filter = defs
    .append("filter")
    .attr("id", "dropshadow")
    .attr("x", "-50%")
    .attr("y", "-50%")
    .attr("height", "200%")
    .attr("width", "200%");

  filter
    .append("feGaussianBlur")
    .attr("in", "SourceAlpha")
    .attr("stdDeviation", 10)
    .attr("result", "blur");
  filter
    .append("feOffset")
    .attr("in", "blur")
    .attr("dx", 0)
    .attr("dy", 0)
    .attr("result", "offsetBlur");
  filter
    .append("feFlood")
    .attr("in", "offsetBlur")
    .attr("flood-opacity", "0.5")
    .attr("result", "offsetColor");
  filter
    .append("feComposite")
    .attr("in", "offsetColor")
    .attr("in2", "offsetBlur")
    .attr("operator", "in")
    .attr("result", "offsetBlur");

  const feMerge = filter.append("feMerge");

  feMerge.append("feMergeNode").attr("in", "offsetBlur");
  feMerge.append("feMergeNode").attr("in", "SourceGraphic");

  return filter;
}

function getZoomPan() {
  if (zoomPan) return zoomPan;

  zoomPan = d3.zoom<SVGSVGElement, unknown>().on("zoom", function (event) {
    if (selectedNode) return;
    if (lastYScrolled + event.transform.y >= 0) {
      event.transform.y = -lastYScrolled;
    }

    yScrolled = lastYScrolled + event.transform.y;
    currentTransform[1] = initialTransform[1] - event.transform.y;
    g.attr("transform", `translate(0, ${yScrolled})`);
    titleSection.style("top", headerDimensions.top + yScrolled + "px");
    footerSection.style("top", footerContainer.top + yScrolled + "px");
  });

  return zoomPan;
}

async function getDetailsModule() {
  if (detailsModule) return detailsModule;
  const module = await import("./components/details/details");
  detailsModule = module;
  return module;
}

async function getExperienceModule() {
  if (experienceModule) return experienceModule;
  await getDetailsModule();
  const module = await import("./components/experience");
  experienceModule = module;
  return module;
}
